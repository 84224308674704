import React, { useContext } from "react";
import { TranslationContext } from "../../translation/translationContext";
import { BLACK, DARK_BLUE } from "../../assets/colors";
import { dateToString } from "../../utils/dateHelper";

const styles = {
  articleContainer: {
    marginTop: 32,
    width: "50%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
  },
  rightContent: {
    display: "flex",
    flex: 4,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  topTextsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 4,
  },
  date: {
    fontFamily: "Roboto Slab",
    fontWeight: 400,
    fontSize: 20,
  },
  title: {
    fontFamily: "Roboto Slab",
    fontWeight: 400,
    fontSize: 24,
  },
  image: {
    marginBottom: 40,
    height: 300,
    width: 500,
    marginRight: 16,
    flex: 3,
  },
  text: {
    fontFamily: "Roboto Slab",
    fontWeight: 400,
    fontSize: 17,
    marginBottom: 24,
    "line-height": "150%",
  },
  line: {
    width: "100%",
    height: 1,
    color: BLACK,
    backgroundColor: BLACK,
  },
  link: {
    alignSelf: "flex-start",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 17,
    marginBottom: 24,
    color: DARK_BLUE,
    textDecoration: "underline",
  },
};

// TODO: Default image?

const EventArticle = ({
  onClick,
  date,
  title,
  image,
  text,
  url,
  extraUrl,
  extraUrlText,
  additionalUrl,
  additionalUrlText,
}) => {
  const { translate } = useContext(TranslationContext);
  const publishDate = dateToString(date);
  return (
    <div style={styles.articleContainer}>
      {image && <img style={styles.image} src={image} alt={title} />}
      <div style={styles.rightContent}>
        <div style={styles.topTextsContainer}>
          <p style={styles.title}>{title}</p>
          <p style={styles.date}>{publishDate}</p>
        </div>
        <div style={styles.line} />
        <p style={styles.text}>{text}</p>
        <a
          style={styles.link}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {translate("readMore")}
        </a>
        {extraUrl && (
          <a
            style={styles.link}
            href={extraUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {extraUrlText}
          </a>
        )}
        {additionalUrl && (
          <a
            style={styles.link}
            href={additionalUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {additionalUrlText}
          </a>
        )}
      </div>
    </div>
  );
};

export default EventArticle;
