import React, { useContext} from 'react';
import { TranslationContext } from '../../translation/translationContext';
import { Dialog, DialogContent } from '@material-ui/core';
import InputWithLabel from '../texts/inputWithLabel';
import StandardButton from '../buttons/standardButton';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    title: {
        marginTop: 16,
        alignSelf: 'flex-start',
        fontWeight: 700,
        fontFamily: 'Roboto',
        fontSize: 20
    },
    input: {
        marginTop: 16,
        width: '100%'
    },
    sendButton: {
        marginTop: 16,
        alignSelf: 'flex-end',
        marginBottom: 16,
    }
};


const SignupDialog = ({ name, email, setName, setEmail, invites, setInvites, onSend, isOpen, close }) => {
    const { translate } = useContext(TranslationContext);
    return (
    <Dialog open={isOpen} onBackdropClick={close}>
        <DialogContent style={styles.container}>
            <p style={styles.title}>{`${translate('enterDetails')}:`}</p>
            <InputWithLabel style={styles.input} label={`${translate('name')}:`} type='text' value={name} setValue={setName} />
            <InputWithLabel style={styles.input} label={`${translate('email')}:`}  type='email' value={email} setValue={setEmail} />
            <InputWithLabel style={styles.input} label={translate('inviteOther')}  value={invites} setValue={setInvites} />
            <StandardButton style={styles.sendButton} text={translate('send')} onClick={onSend} />
        </DialogContent>
    </Dialog>
)};

export default SignupDialog;