import React, { useContext, useState } from "react";
import Screen from "../components/views/screen";
import Navbar from "../components/navbars/insideNavbar";
import { TranslationContext } from "../translation/translationContext";
import { EventsContext } from "../events/eventsContext";
import { LIGHT_BLUE } from "../assets/colors";
import { insideNavbarHeight } from "../assets/dimensions";
import ScrollButton from "../components/buttons/scrollButton";
import EventArticle from "../components/views/eventArticle";
import SignupDialog from "../components/dialogs/signupDialog";
import logo from "../assets/images/logo.svg";

const styles = {
  container: {
    backgroundColor: LIGHT_BLUE,
    paddingTop: insideNavbarHeight + 48,
    paddingBottom: 800,
  },
  scrollButton: {
    position: "fixed",
    bottom: 50,
    right: 50,
  },
  infoSquare: {
    paddingRight: 48,
    paddingTop: 48,
    paddingBottom: 48,
    paddingLeft: 48,
    backgroundColor: "white",
    "box-shadow": "4px 3px 19px 9px rgba(0, 0, 0, 0.25)",
    marginBottom: 800,
    marginTop: 150,
  },
  noEvents: {
    fontFamily: "Roboto",
    fontSize: 24,
    textAlign: "center",
  },
};

const EventsPage = () => {
  const { translate } = useContext(TranslationContext);
  const { events, join } = useContext(EventsContext);
  const [name, setName] = useState(""); // TODO: Use user name
  const [email, setEmail] = useState(""); // TODO: Use user email
  const [invites, setInvites] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openEventId, setOpenEventId] = useState();

  const pickEvent = (eventId) => {
    setOpenEventId(eventId);
    setDialogOpen(true);
  };

  const onSend = () => {
    setDialogOpen(false);
    //TODO: Call join
  };

  return (
    <Screen style={styles.container}>
      <Navbar currentTab={"/events"} />
      <SignupDialog
        name={name}
        setName={setName}
        email={email}
        setEmail={setEmail}
        invites={invites}
        setInvites={setInvites}
        isOpen={dialogOpen}
        close={() => setDialogOpen(false)}
        onSend={onSend}
      />
      {events.map(
        ({
          id,
          date,
          title,
          image,
          text,
          url,
          extraUrl,
          extraUrlText,
          additionalUrl,
          additionalUrlText,
        }) => (
          <EventArticle
            date={date}
            title={title}
            image={image}
            text={text}
            url={url}
            extraUrl={extraUrl}
            extraUrlText={extraUrlText}
            additionalUrl={additionalUrl}
            additionalUrlText={additionalUrlText}
          />
        )
      )}
      {!events.length && (
        <div style={styles.infoSquare}>
          <img src={logo} alt="logo" />
          <p style={styles.noEvents}>{translate("noEvents")}</p>
        </div>
      )}
      <ScrollButton style={styles.scrollButton} />
    </Screen>
  );
};

export default EventsPage;
